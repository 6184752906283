export default currencyCode => {
  const currencyInfo = JSON.parse(sessionStorage.getItem('currencyInfo') || {})
  const { currencies } = currencyInfo
  if (!currencies) return
  const b3CurrentCurrency = currencies.find(({ is_default: isDefault, currency_code: b3currencyCode }) => (currencyCode ? currencyCode === b3currencyCode : isDefault))
  return {
    ...b3CurrentCurrency,
    thousands_token: b3CurrentCurrency?.thousands_token,
    decimal_places: b3CurrentCurrency?.decimal_places,
    decimal_token: b3CurrentCurrency?.decimal_token,
    token_location: b3CurrentCurrency?.token_location,
    token: b3CurrentCurrency?.token,
    currency_exchange_rate: b3CurrentCurrency?.currency_exchange_rate,
  }
}
