export default class browserCheck {
  constructor() {
      this.data = {}
      this.browser = null
      this.version = null
      this.os = null
      this.webkit = null
  }

  init() {
    this.parseUserAgent()
    this.renameOsx()
    this.cutSafariVersion()
    this.prepareData()

    return this.data
  }

  parseUserAgent() {
      const userAgent = navigator.userAgent.toLowerCase()
      const browserParts = /(ie|firefox|chrome|safari|opera|edge)(?:.*version)?(?:[ \\/])?([\w.]+)/.exec(userAgent)
      const osParts = /(mac|win|linux|freebsd|mobile|iphone|ipod|ipad|android|blackberry|j2me|webtv)/.exec(userAgent)

      if (userAgent.match(/trident\/7\./)) {
        this.browser = 'ie'
        this.version = 11
      } else if (userAgent.match(/edge\/\d+/)) {
        const edgeBrowserParts = /(edge)\/([\w.]+)/.exec(userAgent)
        this.browser = edgeBrowserParts[1] ? edgeBrowserParts[1] : ''
        this.version = edgeBrowserParts[2] ? edgeBrowserParts[2] : ''
      } else if (browserParts && browserParts.length > 2) {
        this.browser = browserParts[1] ? browserParts[1] : ''
        this.version = browserParts[2] ? browserParts[2] : ''
      }

      if (osParts && osParts.length > 1) {
        this.os = osParts[1] ? osParts[1] : ''
      }

      this.webkit = navigator.userAgent.toLowerCase().match(/webkit\/(\d+)\.(\d+)\.(\d+)/)
  }

  prepareData() {
    this.data.browser = this.browser
    this.data.version = parseInt(this.version, 10) || null
    this.data.os = this.os
    if (this.webkit && this.webkit.length >= 3) {
      this.data.webkit = {
        major: parseInt(this.webkit[1], 10),
        minor: parseInt(this.webkit[2], 10),
        patch: this.webkit[3] ? parseInt(this.webkit[3], 10) : undefined,
      }
    }
  }

  renameOsx() {
    if (this.os === 'mac') {
      this.os = 'osx'
    }
  }

  cutSafariVersion() {
    if (this.os === 'safari') {
      this.version = this.version.substring(0, 1)
    }
  }
}
