const handleStencilSearchInfo = searchInfo => {
  let newResponse = searchInfo
  const str = '<script>window.bodlEvents.product.emit'

  if (newResponse.includes(str)) {
    const res = searchInfo.split(str)[0]
    newResponse = res
  }

  return newResponse
}

export default handleStencilSearchInfo
