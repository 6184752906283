import axios from 'axios'
import { isEmpty } from 'lodash'
import Alert from '../utils/Alert'
import themeConfig from '../../themeConfig'
import B3Storage from '../utils/B3Storage'
import exitLogin from '../utils/logout'

const b3request = axios.create({
  baseURL: themeConfig.apiBaseUrl,
  timeout: 1000 * 10 * 60 * 5,
})

b3request.interceptors.request.use(config => {
  const { channelId } = window.jsContext
  const authToken = B3Storage.B3B2BToken.value

  config.headers = {
    ...config.headers,
    authToken,
  }

  // BB2BV30-1340: B2C and GUEST need channelId in some api
  if (!authToken) {
    if (config.data) {
      if (Object.prototype.toString.call(config.data) === '[object FormData]') {
        const { data } = config
        data.append('channelId', channelId)
        config.data = data
      } else {
        config.data = {
          ...config.data,
          channelId,
        }
      }
    }

    if (config.params) {
      if (Object.prototype.toString.call(config.params) === '[object FormData]') {
        const { params } = config
        params.append('channelId', channelId)
        config.params = params
      } else {
        config.params = {
          ...config.params,
          channelId,
        }
      }
    }
  }

  return config
})

b3request.interceptors.response.use(resp => {
  const {
    code,
    meta,
  } = resp.data

  if (resp.config.url.includes('v2/proxy') && !resp.data.data?.errMsg) {
    return resp.data
  }

  let { data, message } = resp.data

  if (!isEmpty(resp.data) && resp.headers['content-type'] === 'text/csv') {
    return resp.data
  }

  const {
    params: {
      SHOWERROR = true,
    } = {},
  } = resp.config

  if (meta) {
    message = meta.errors || meta.message
    data = { data, meta }
  }

  if (code !== 200) {
    if (code === 403 && message === 'Multi storefront is not enabled') return Promise.resolve()
    window.B3Spinner.hide()

    const { data: realData, meta } = data
    const errorData = realData || data
    // if there are any specific error messages list in data object, need show them here
    let specificMsg = ''
    if (errorData && Object.keys(errorData).length) {
      specificMsg += Object.values(errorData).join(';\n')
    }

    if (code === 40101) {
      Alert.error(specificMsg || message)
      exitLogin()
      return Promise.reject(message)
    }
    SHOWERROR && Alert.error(specificMsg || message)
    return Promise.reject(meta || resp.data)
  }

  return data
})

export default b3request
