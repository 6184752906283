import { merge } from 'lodash'
import * as b3lang from '../../b3lang'

export default class LanguageService {
  constructor() {
    this.langCodeList = []
    this.langList = b3lang
    this.defaultLangCode = "en"
    this.defaultLangList = { ...b3lang[this.defaultLangCode] }
    this.currentLangCode = this.defaultLangCode
  }

  static create() {
    const instance = new this()
    instance.createLangCodeList()
    instance.createLangList()
    instance.setCurrentLangCode()

    // Priority: multiLang_config will cover useText_config
    // if customer is using multiLang config
    if (window.b3themeConfig) {
      const { lang, locales } = window.b3themeConfig

      if (lang && locales) {
        return merge({}, instance.defaultLangList, instance.langList[instance.currentLangCode])
      }
    }

    // else if customer is using useText config
    const useText = window.b3themeConfig?.useText ?? {}
    return merge({}, instance.defaultLangList, useText)
  }

  createLangCodeList() {
    if (window.b3themeConfig?.lang) this.langCodeList.push(window.b3themeConfig.lang)
    const { languages = [], language, userLanguage } = navigator
    this.langCodeList = [...new Set(
      [...this.langCodeList, ...languages, language, userLanguage]
    )].filter(language => language)
  }

  createLangList() {
    this.langList = {
      ...this.langList,
      ...window.b3themeConfig?.locales ?? {}
    }
  }

  setCurrentLangCode() {
    for (let i = 0; i < this.langCodeList.length; i++) {
      if (this.langList.hasOwnProperty(this.langCodeList[i])) {
        this.currentLangCode = this.langCodeList[i]
        break
      }
      if (this.langList.hasOwnProperty(this.langCodeList[i].substring(0, 2))) {
        this.currentLangCode = this.langCodeList[i].substring(0, 2)
        break
      }
    }

    window.b3CurrentLangCode = this.currentLangCode
  }
}
