const CSV_FILE_NAME = {
  invoices: 'Invoice List.csv',
}

const exportCSVFile = (data, apiPath) => {
  const fileName = CSV_FILE_NAME[apiPath]
  const BOM = '\uFEFF'
  const href = URL.createObjectURL(new Blob([BOM + data], { type: 'text/csv' }))
  let a = document.createElement('a')
  a.href = href
  a.download = `${fileName}`
  a.click()
  URL.revokeObjectURL(href)
  a = null
}

export default exportCSVFile
