import xss from 'xss'

const filterXSS = new xss.FilterXSS({
  onIgnoreTag(tag, html) {
    if (tag === 'script') {
      return undefined
    }
    return html
  },
  onIgnoreTagAttr(tag, name, value) {
    if (/^(on|On|oN|ON)/.test(name)) {
      return undefined
    }
    return `${name}="${value}"`
  },
})

export default function b3FilterXSS(html = '') {
  return filterXSS.process(html)
}
