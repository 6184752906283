import { DEFAULT_IMAGE_URL } from '../../hbs/helpers/getImage'

const searchImgSize = '250x100'

const getCorrectProductImage = (product, imageKey = 'image') => {
  const url = DEFAULT_IMAGE_URL
  product[imageKey] = product[imageKey] || { data: url }
  product[imageKey].data = product[imageKey].data.replace('{:size}', searchImgSize)

  if (imageKey && !product[imageKey]) product[imageKey] = url

  return product
}

export default getCorrectProductImage

export const getCorrectProductImages = (data, imageKey) => data.map(product => getCorrectProductImage(product, imageKey))
