import swal from 'sweetalert2'

import langText from '../../text'

export const DEFAULT_ALERT_OPTIONS = {
  confirmButtonText: langText['global.alert.buttonText.ok'],
  cancelButtonText: langText['global.alert.buttonText.cancel'],
}

/**
 * Alert object
 * @example
 * // display an error Alert
 * import Alert from 'relative-path/Alert';
 * Alert.error(errorMessage);
 */
export default {
  async error(text, options) {
    return swal({
      type: 'error',
      text,
      ...DEFAULT_ALERT_OPTIONS,
      ...options,
    })
  },
  async success(text, options) {
    return swal({
      type: 'success',
      text,
      ...DEFAULT_ALERT_OPTIONS,
      ...options,
    })
  },
  async warning(text, options) {
    return swal({
      type: 'warning',
      text,
      ...DEFAULT_ALERT_OPTIONS,
      ...options,
    })
  },
  async info(text, options) {
    return swal({
      type: 'info',
      text,
      ...DEFAULT_ALERT_OPTIONS,
      ...options,
    })
  },
}
