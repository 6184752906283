export default {
  // navigation button text
  'nav.button.dashboard': 'Dashboard',
  'nav.button.quotes': 'Quotes',
  'nav.button.userManagement': 'User Management',
  'nav.button.buyAgain': 'Buy Again',
  'nav.button.addressBook': 'Addresses',
  'nav.button.quickOrderPad': 'Quick Order Pad',
  'nav.button.shoppinglists': 'Shopping Lists',
  'nav.button.tpa': 'TRADE PROFESSIONAL APPLICATION',
  'nav.button.quotesList': 'Quotes',
  'nav.button.invoices': 'Invoices',
  'nav.button.myQuote': 'My Quote',
  // global
  'global.required.label': 'REQUIRED',
  'global.user.firstName.label': 'First Name',
  'global.user.lastName.label': 'Last Name',
  'global.user.city.label': 'City',
  'global.user.country.label': 'Country',
  'global.user.state.label': 'State',
  'global.user.zipcode.label': 'ZIP Code',
  'global.user.companyEmail.label': 'Email Address',
  'global.user.address1.label': 'Address Line 1',
  'global.user.address2.label': 'Address Line 2',
  'global.user.phone.label': 'Phone Number',
  'global.user.email.label': 'Email',
  'global.user.admin.label': 'Admin',
  'global.user.senior.label': 'Senior buyer',
  'global.user.junior.label': 'Junior buyer',
  'global.user.superAdmin.label': 'Super admin',
  'global.user.name.label': 'Name',
  'global.user.role.label': 'Role',
  'global.toolbar.unit': 'Items',
  'global.user.masquerade.end.button': 'End Masquerade',
  'global.user.masquerade.begin.button': 'Begin Masquerade',
  'global.form.sure': 'Sure',
  'global.form.close': 'Close',
  'global.form.today': 'Today',
  'global.form.clear': 'Clear',
  'global.B3Paginator.previous': 'Previous',
  'global.B3Paginator.next': 'Next',
  'global.month': 'Month',
  'global.year': 'Year',
  'global.day': 'Day',
  'global.none': 'None',
  'global.longMonths.January': 'January',
  'global.longMonths.February': 'February',
  'global.longMonths.March': 'March',
  'global.longMonths.April': 'April',
  'global.longMonths.May': 'May',
  'global.longMonths.June': 'June',
  'global.longMonths.July': 'July',
  'global.longMonths.August': 'August',
  'global.longMonths.September': 'September',
  'global.longMonths.October': 'October',
  'global.longMonths.November': 'November',
  'global.longMonths.December': 'December',
  'global.shortMonths.Jan': 'Jan',
  'global.shortMonths.Feb': 'Feb',
  'global.shortMonths.Mar': 'Mar',
  'global.shortMonths.Apr': 'Apr',
  'global.shortMonths.May': 'May',
  'global.shortMonths.Jun': 'Jun',
  'global.shortMonths.Jul': 'Jul',
  'global.shortMonths.Aug': 'Aug',
  'global.shortMonths.Sep': 'Sep',
  'global.shortMonths.Oct': 'Oct',
  'global.shortMonths.Nov': 'Nov',
  'global.shortMonths.Dec': 'Dec',
  'global.date.Sun': 'Sun',
  'global.date.Mon': 'Mon',
  'global.date.Tue': 'Tue',
  'global.date.Wed': 'Wed',
  'global.date.Thu': 'Thu',
  'global.date.Fri': 'Fri',
  'global.date.Sat': 'Sat',
  'global.alert.buttonText.ok': 'OK',
  'global.alert.buttonText.confirm': 'Sure',
  'global.alert.buttonText.cancel': 'Cancel',
  'global.alert.buttonText.delete': 'Delete',
  'global.alert.warning.delete': 'Are you sure you want to delete this item?',
  'global.alert.warning.companyCreditHold': 'Your account does not currently allow purchasing due to a credit hold. Please contact us to reconcile.',

  // Trade Application Professional
  'tpa.button': 'Trade Professional Application',
  'tpa.form.company.email.label': 'Company Email Address',
  'tpa.form.company.phone.number.label': 'Company Phone Number',
  'tpa.form.company.admin.account.title': 'Company Admin Account Information',
  'tpa.form.company.info.title': 'Company Information',
  'tpa.form.company.name.label': 'Company Name',
  'tpa.form.resubmit.button': 'RESUBMIT',
  'tpa.form.submit.button': 'Submit',
  'tpa.tip.company.name.duplicated': 'company name duplicated',
  'tpa.message.company.exist': 'You are already in company',
  'tpa.message.company.pending': 'Company application status: {companyStatusLabel}.Your application is under review, please wait...',
  'tpa.message.company.reject': 'Company application status: {companyStatusLabel}.Your application has been rejected, you can resubmit your application.',
  'tpa.message.company.others': 'Your Company cannot be used, please wait...',
  'tpa.field.validation.cannot.blank': 'The {validationLabel} field cannot be blank.',
  'tpa.field.validation.message.value.valid': 'The value for {validationLabel} is not valid.',
  // user management
  'userManagement.search.placeholder': 'Search by keyword',
  'user.form.save.button': 'Save',
  'user.form.cancel.button': 'Cancel',
  'user.form.edit.title': 'Edit User',
  'user.form.edit.button': 'Edit',
  'user.form.delete.button': 'Delete',
  'user.form.role.label': 'User Role',
  'user.thead.action': 'Action',
  'user.list.title': 'Company Users',
  'user.form.add.button': 'Add New User',
  // shopping list
  'shopping.list.title': 'Shopping Lists',
  'shopping.list.create.button': 'Create New Shopping List',
  'shopping.list.save.button': 'Save',
  'shopping.list.cancel.button': 'Cancel',
  'shopping.list.action.view': 'View',
  'shopping.list.action.delete': 'Delete',
  'shopping.list.action.duplicate': 'Duplicate',
  'shopping.list.edit.info.button': 'Edit List Info',
  'shopping.list.confirm.button': 'Confirm',
  'shopping.list.approval.button': 'Approve',
  'shopping.list.reject.button': 'Reject',
  'shopping.list.delete.selected.button': 'Delete Selected',
  'shopping.list.submit.approval.button': 'Submit for Approval',
  'shopping.list.add.to.list.title': 'Add to List',
  'shopping.list.choose.file.button': 'Choose File',
  'shopping.list.download.sample.csv.button': 'Download Sample CSV',
  'shopping.list.save.options.button': 'Save Options',
  'shopping.list.add.to.cart.button': 'Add To Cart',
  'shopping.list.clear.cart.button': 'Clear Cart',
  'shopping.list.go.to.checkout.button': 'Go To Checkout',
  'shopping.list.thead.name': 'Name & Description',
  'shopping.list.thead.createdBy': 'Created By',
  'shopping.list.thead.latest': 'Latest Activity',
  'shopping.list.thead.status': 'Status',
  'shopping.list.thead.action': 'Action',
  'shopping.list.create.date': 'Date Created: ',
  'shopping.list.description': 'Descriptions: ',
  'shopping.list.last.update': 'Last Updated: ',
  'shopping.list.create.by': 'Created By: ',
  'shopping.list.status.select.label': 'Please select a Status: ',
  'shopping.list.status.show.label': 'Status: ',
  'shopping.list.unit.text': 'Items',
  'shopping.list.thead.price': 'Price',
  'shopping.list.thead.qty': 'Qty',
  'shopping.list.thead.subtotal': 'Subtotal',
  'shopping.list.single.search.label': 'Enter SKU or Product Name',
  'shopping.list.button.more': 'More Results',
  'shopping.list.divider.or': '-OR-',
  'shopping.list.multiple.search.label': 'Enter Multiple SKUs',
  'shopping.list.multiple.search.tip': 'Use Commas to separate SKUs. <br/> eg SKU01, SKU 02',
  'shopping.list.upload.label': 'Add from File',
  'shopping.list.td.sku.label': 'SKU: ',
  'shopping.list.td.price.label': 'Price: ',
  'shopping.list.td.qty.label': 'Qty: ',
  'shopping.list.td.subtotal.label': 'Subtotal: ',
  'shopping.list.button.updateQty': 'Update Quantity',
  'shopping.list.button.editOptions': 'Edit Options',
  'shopping.list.edit.modal.title': 'Edit Shopping List',
  'shopping.list.edit.modal.form.name.label': 'Shopping List Name',
  'shopping.list.edit.modal.form.description.label': 'Description',
  'shopping.list.options.modal.title': 'Choose Options',
  'shopping.list.item.grand.total': 'Grand Total:',
  'shopping.list.createModal.title.label': 'Duplicate Shopping List',
  'shopping.list.bottomCart.item': 'Item(s) in Your Cart',
  'shopping.list.bottomCart.subtotal': 'Subtotal:',
  'shopping.list.sku': 'SKU:',
  'shopping.list.sold': 'Sold Out',
  'shopping.list.total': 'Total',
  'shopping.list.search.placeholder': 'Search SKU or Product Name',
  'shopping.list.no.products': 'No products found for',
  'shopping.list.qty': 'Qty:',
  'shopping.list.checkout.form.label': 'PO #',
  'shopping.list.checkout.payment.btn': 'Place Order',
  'shopping.list.checkout.poNumberErr': 'PO # is required',
  'shopping.list.creatNewListBtn': 'Add To a New List',
  'shopping.list.addToBtn': 'Add To {name}',
  'shopping.list.status.approved': 'Approved',
  'shopping.list.status.deleted': 'Deleted',
  'shopping.list.status.draft': 'Draft',
  'shopping.list.status.readyForApproval': 'Ready for Approval',
  'shopping.list.status.showAllStatus': 'Show All Status',
  'shopping.list.status.showReadyForApproval': 'Show Ready for Approval',
  'shopping.list.status.showDraft': 'Show Draft',
  'shopping.list.status.showApproved': 'Show Approved',
  'shopping.list.inputCheckbox.yes': 'Yes',
  'shopping.list.edit.options': "Click 'Edit Options' to set a value for this option.",
  'shopping.list.upload.errorMessage': 'Empty file, please upload another.',
  'shopping.list.upload.limit.errorMessage': 'The file cannot exceed 300 lines.',
  'shopping.list.file.processed': 'File has been processed',
  'shopping.list.fileCheck.errorMessage': 'Your file doesn\'t pass our "Advance Quantity" check, please correct them and upload the file again.',
  'shopping.list.fileProcessed.errorMessage': 'File could not be processed. Please re-upload with correct data.',
  'shopping.list.fileUpload.errorMessage': 'Your file has {errorCounter} errors, please correct them and upload the file again.',
  'shopping.list.products.notFound': 'No products found.',
  'shopping.list.addProducts.successTips': 'success',
  'shopping.list.saveUpdate.errorMessage': 'No variant Id',

  // quotes
  'quotes.list.title': 'Quotes',
  'quotes.action.view': 'View',
  'quotes.action.checkout': 'Checkout',
  'quotes.action.view.order': 'View Order',
  'quotes.action.edit': 'Edit',
  'quotes.action.delete': 'Delete',
  'quotes.back.to.edit': 'Back to Edit',
  'quotes.back.to.all': 'Back to All Quotes',
  'quotes.list.quoteNumber': 'Quote Number',
  'quotes.list.quoteTitle': 'Quote Title',
  'quotes.list.salesRep': 'Sales Rep',
  'quotes.list.createdBy': 'Created By',
  'quotes.list.createdAt': 'Date Created',
  'quotes.list.updatedAt': 'Last Update',
  'quotes.list.expiredAt': 'Expiration Date',
  'quotes.list.total': 'Total',
  'quotes.list.status': 'Status',
  'quotes.list.status.open': 'Open',
  'quotes.list.status.ordered': 'Ordered',
  'quotes.list.status.expired': 'Expired',
  'quotes.list.actions': 'Actions',
  'quotes.list.items': 'Items',
  'quotes.search.placeholder': 'Search my quotes...',
  'quotes.filters': 'Filters',
  'quotes.filters.dateCreatedBeginAt': 'Date Created',
  'quotes.filters.dateUpdatedBeginAt': 'Last Update',
  'quotes.filters.dateExpiredBeginAt': 'Expiration Date',
  'quotes.filters.clear': 'CLEAR',
  'quotes.filters.apply': 'APPLY',
  'quote.checkout.addPrompt': 'Please log in to purchase this quote',
  // rfq
  'rfq.company.quote': 'Quote:',
  'rfq.company.reference-number': 'Reference Number:',
  'rfq.company.issued': 'Issued On:',
  'rfq.company.expiration': 'Expiration Date:',
  'rfq.company.sales-representative': 'Sales Representative',
  'rfq.items.items': 'Line Items',
  'rfq.items.qty': 'Qty',
  'rfq.items.price': 'Price',
  'rfq.items.offered.price': 'Quoted Price',
  'rfq.items.subtotal': 'Subtotal',
  'rfq.items.offered-subtotal': 'Quoted Subtotal',
  'rfq.orginal.total': 'Original Total:',
  'rfq.discount': 'Discount Amount:',
  'rfq.offered.Total': 'Quoted Total:',
  'rfq.price.shipping': 'Shipping({ShippingName}):',
  'rfq.price.tax': 'Tax:',
  'rfq.price.shipping.type': 'Shipping(Customer choice at checkout)',
  'rfq.price.shipping.typeFront': 'Shipping',
  'rfq.price.shipping.TBD': 'TBD',
  'rfq.price.shipping.estimatedTax': 'Estimated Tax',
  'rfq.price.grandTotal': 'Grand Total:',
  'rfq.sku': 'SKU',
  'rfq.notes': 'Notes',
  'rfq.trackings.title': 'Tracking History',
  'rfq.message.title': 'Message',
  'rfq.additionalInfo.title': 'Additional Information',
  'rfq.additionalInfo.notes': 'Notes',
  'rfq.additionalInfo.legalTerms': 'Terms and Conditions',
  'rfq.message.input.placeholder': 'Please enter your message here if you have any comments or questions.',
  'rfq.send.message.btn': 'Add Message',
  'rfq.checkout.btn': 'Proceed to Checkout',
  'rfq.form.success.title': 'Submitted successfully',
  'rfq.form.title.success': 'Success!',
  'rfq.form.tip1': 'You can view your submitted quote request and send additional messages using this',
  'rfq.form.link': 'link',
  'rfq.form.to': 'to',
  'rfq.form.tip2': 'Once your request has been reviewed, you will receive an email to view the latest status and updated details of your quote.',
  'rfq.form.label.isRequired': '{label} is required',
  'rfq.header.linkCopy': 'Copy Quote Link',
  'rfq.header.copied': 'Copied',
  'rfq.header.print': 'Print',
  'rfq.header.pdfdownload': 'Download PDF',
  'rfq.produckts.noStock': 'No Products',
  'rfq.detail.shippingAddress': 'Shipping Address',
  'rfq.detail.billingAddress': 'Billing Address',
  'rfq.detail.contact': 'Contact',
  'rfq.quote.detail.attachment.file.title': 'Attachments',
  'rfq.quote.detail.attachment.file.salesRep': 'Uploaded By Sales rep',
  'rfq.quote.detail.attachment.file.customer': 'Uploaded By Customer',
  'rfq.quote.detail.attachment.file.noAttachments': 'No attachments',
  'rfq.form.select.chooseOptions': 'Choose Options',
  'rfq.form.select.address': 'Select an Address',
  'rfq.error.formNotExist': 'Quote Form Does not Exist',
  'rfq.required.errorMessage': 'is required',
  'rfq.button.addAll': 'Add All to Quote',
  'rfq.button.add': 'Add to Quote',
  'rfq.button.added': 'Added',
  'rfq.button.viewQuote': 'VIEW MY QUOTE',
  'rfq.form.title': 'Request a Quote',
  'rfq.form.title.quoteInfo': 'Quote Information',
  'rfq.form.title.addressInfo': 'Shipping Address',
  'rfq.form.title.billingAddressInfo': 'Billing Address',
  'rfq.form.title.addressLabel': 'Address Label',
  'rfq.form.title.phoneNumber': 'Phone Number',
  'rfq.form.address.search.placeholder': 'search an address...',
  'rfq.form.address.title': 'Select an Address',
  'rfq.form.address.noData': 'There are no saved addresses.',
  'rfq.form.field.contactEmail': 'Contact Email',
  'rfq.form.field.contactName': 'Contact Name',
  'rfq.form.field.companyName': 'Company Name',
  'rfq.form.field.phoneNumber': 'Phone Number',
  'rfq.form.field.quoteTitle': 'Quote Title',
  'rfq.form.field.referenceNumber': 'Reference Number',
  'rfq.form.field.address': 'Address',
  'rfq.form.field.firstName': 'First Name',
  'rfq.form.field.lastName': 'Last Name',
  'rfq.form.field.apartment': 'Apartment',
  'rfq.form.field.city': 'City',
  'rfq.form.field.country': 'Country',
  'rfq.form.field.state': 'State',
  'rfq.form.field.zipCode': 'ZipCode',
  'rfq.form.field.expiredAt': 'Expiration Date',
  'rfq.form.field.sameAddressTip': 'My Billing address is the same as shipping address',
  'rfq.form.message.title': 'Message',
  'rfq.form.attach.file.title': 'Attach file',
  'rfq.form.attach.file.limitNumTipText': 'File number limit: 3',
  'rfq.form.attach.file.formatSupportTipText': 'Allowed file types: pdf, doc, docx, xls, xlsx, csv, png, jpg, jpeg, bmp, gif, svg, ico, tif, tiff, webp',
  'rfq.form.attach.file.fileSizeTipText': 'Single file size limit: 2MB',
  'rfq.form.attach.file.limitNumTip': 'Up to 3 attachments can be uploaded!',
  'rfq.form.attach.file.formatSupportTip': 'The format is not supported!',
  'rfq.form.attach.file.fileSizeTip': 'The file size exceeds the limit, please limit it to 2M!',
  'rfq.form.button.select.address': 'Select an Address',
  'rfq.form.button.save.address': 'Save Address',
  'rfq.form.button.submit': 'SUBMIT',
  'rfq.form.button.close': 'Close',
  'rfq.form.item.sku': 'SKU',
  'rfq.form.search.label': 'Add Products',
  'rfq.form.search.input.placeholder': 'Search Sku or Product Name',
  'rfq.form.search.noProductFound': 'No products found.',
  'rfq.form.search.selectOptions.title': 'Choose options',
  'rfq.form.search.noSku.tip': 'NO SKU',
  'rfq.form.edit.options': 'Edit Options',
  'rfq.form.edit.options.cancel': 'CANCEL',
  'rfq.form.edit.options.save': 'SAVE',
  'rfq.form.edit.options.selectTip': 'The selected product combination is currently unavailable.',

  // quick order pad
  'qop.upload.csv.title': 'Upload via CSV',
  'qop.download.csv.title': 'Download Sample CSV File',
  'qop.choose.file.button': 'Choose File',
  'qop.add.to.cart.button': 'Add To Cart',
  'qop.upload.sku.title': 'Quick Order via SKU',
  'qop.add.row.button': 'Add Row',
  'qop.thead.variantSku': 'Variant SKU',
  'qop.thead.quantity': 'Quantity',
  'qop.tbody.invalidSku': 'Invalid SKU ',
  'qop.upload.click.lable': 'Click to  "Browse File"',
  'qop.upload.drag.lable': ' or "Drag and Drop" to Upload.',
  'qop.checking.file': 'Checking file...',
  'qop.redundant.data': 'redundant data;',
  'qop.empty.sku': 'variant_sku can\'t be empty;',
  'qop.limit.lines': 'The file cannot exceed 300 lines.',
  'qop.empty.qty': 'qty can\'t be empty;',
  'qop.integer.qty': 'qty must be an integer;',
  'qop.ignored.qty': '0 qty will be ignored;',
  'qop.row.errorInfo': '#ROW {index}: {errorInfo}',
  'qop.sku.maximum': 'The maximum purchasable quantity of {sku} is {maxQuantity}.',
  'qop.sku.minimum': 'The minimum purchasable quantity of {sku} is {minQuantity}.',
  'qop.sku.invisible': '{sku} is invisible on storefront.',
  'qop.sku.cannotAdd': '{sku} cannot be added to cart via the Quick Order Pad.',
  'qop.sku.stock': '{sku} is out of stock.',
  'qop.sku.exceed.stock.csv': '{SKU} does not have enough stock, please change the quantity. Stock Available amount - {stockNumber}',
  'qop.sku.exceed.stock': '{SKU} Stock Available amount - {stockNumber}',
  'qop.sku.select': 'select',
  'qop.sku.notBelongAnyCategory': '{sku} cannot be uploaded. (Missing Category)',
  'qop.sku.purchasingDisabled': 'This product cannot be purchased in online store.',
  'qop.out.off.stock': 'Out of Stock',
  'qop.success.add': '{successNumber} Line Items has been added to cart',
  'qop.sku.valid': '{sku} is not a valid SKU.',
  'qop.sku.error': '{sku}:{errorInfo} error.',
  'qop.quantity.valid': 'The quantity for {newDataSku} is not valid',
  'qop.loading.products': 'Loading products...',
  'qop.invalid.sku': 'Invalid SKU',
  'qop.invalid.quantity': 'Invalid Quantity',
  'qop.min.quantity': 'Min Quantity {minQty}',
  'qop.product.sku': 'SKU',

  // orders
  'orders.show.all.button': 'Show All Company Orders',
  'orders.show.my.button': 'Show My Orders',
  'orders.reorder.button': 'Reorder',
  'orders.add.to.new.shopping.list.button': 'Add to Shopping List',
  'orders.return.button': 'Return',
  'orders.search.placeholder': 'Search by keyword',
  'orders.filters.label': 'Filters',
  'orders.filters.apply': 'APPLY',
  'orders.filters.clear': 'CLEAR',
  'orders.filters.orderNumber.label': 'Order Number',
  'orders.filters.companyName.label': 'Company Name',
  'orders.filters.poNumber.label': 'PO Number',
  'orders.filters.createdBy.label': 'Created By',
  'orders.thead.orderNumber': 'Order Number',
  'orders.thead.total': 'Product Total',
  'orders.thead.grandTotal': 'Grand Total',
  'orders.thead.po': 'PO# / Reference#',
  'orders.thead.placed': 'Order Placed',
  'orders.thead.purchaseDate': 'Purchase Date',
  'orders.thead.updated': 'Last Updated',
  'orders.thead.created': 'Created By',
  'orders.thead.placedBy': 'Placed By',
  'orders.thead.status': 'Status',
  'orders.thead.action': 'Action',
  'orders.thead.companyName': 'Company Name',
  'order.print.invoice.button': 'Print Invoice',
  'order.title': 'Order Details',
  'order.contents': 'Order Contents',
  'order.coupon.code.label': 'Coupon Code',
  'order.coupon.shipping.label': 'Shipping: ',
  'order.coupon.tax.label': 'Tax: ',
  'order.coupon.subtotal': 'Subtotal: ',
  'order.coupon.discount': 'Discount: ',
  'order.gift_certificate_amount': 'Gift certificates:',
  'order.coupon.storeCredit.label': 'Store Credit: ',
  'order.coupon.grandTotal.label': 'Grand Total: ',
  'order.info.status': 'Order status:',
  'order.info.date': 'Order date:',
  'order.info.total': 'Order total:',
  'order.info.payment': 'Payment method:',
  'order.info.purchase': 'Purchase Order',
  'order.sidebar.shipTo': 'Ship To',
  'order.sidebar.billTo': 'Bill To',
  'order.shipping.date': 'Date Shipped:',
  'order.shipping.provider': 'Shipping Provider:',
  'order.shipping.method': 'Shipping Method:',
  'order.shipping.tracking': 'tracking#',
  'order.shipping.title': 'Shipping Details',
  'order.comment.title': 'Order Comments',
  'order.po.title': 'PO# / Reference#',
  'order.actions.title': 'Actions',
  'order.modal.close': 'Close',
  'order.detail.orderDate': '{day}{afterDate} {currentMonth} {year}',
  'order.detail.id.label': 'Order # {orderId}',
  'order.detail.last.update': 'Last Updated: ',
  'order.detail.create.by': 'Created By: ',
  'order.detail.checkbox.label': 'Checkbox {variantId} label',
  'order.reorder.failTip': 'Unfortunately this product is no longer for sale so it cannot be reordered.',
  'order.reorder.errorTip': 'Please select one or more items to reorder.',
  'order.reorder.successTip': 'Product(s) added to cart',
  'order.reorder.qtyErrorTip': 'Sorry! The following products cannot be added',
  'order.addTo.shoppingList.errorTip': 'Please select one or more items.',
  'order.confirmations.buttonText': 'SUBMIT',
  'order.confirmations.errorMessage': 'An error has occurred submitting your PO #/Reference #, please try it again or contact our customer support.',
  'order.addTo.shoppingListBtn': 'Add to Shopping List',
  'order.showShoppingList.cancel.button': 'Cancel',
  'order.showShoppingList.confirm.button': 'Confirm',
  'order.details.notes': 'Notes',
  // address book
  'address.add.button': 'ADD NEW ADDRESS',
  'address.search.placeholder': 'Search by keyword',
  'address.filters.button': 'Filters',
  'address.filters.address.label': 'Address',
  'address.filters.country.label': 'Country',
  'address.filters.country.palceholder': 'Please Select',
  'address.filters.addressLabel.label': 'Address Label',
  'address.filters.addressType.label': 'Address Type',
  'address.filters.addressType.shipping': 'Shipping',
  'address.filters.addressType.billing': 'Billing',
  'address.clear.button': 'CLEAR',
  'address.apply.button': 'APPLY',
  'address.actions.edit': 'Edit',
  'address.actions.delete': 'Delete',
  'address.actions.default.billing': 'Set as default billing',
  'address.actions.default.shipping': 'Set as default shipping',
  'address.cancel.button': 'Cancel',
  'address.save.button': 'Save',
  'address.thead.address': 'Address',
  'address.thead.addressLabel': 'Address Label',
  'address.thead.country': 'Country',
  'address.thead.shipping': 'Shipping',
  'address.thead.billing': 'Billing',
  'address.thead.action': 'Action',
  'address.formModal.shipping.label': 'Shipping',
  'address.formModal.billing.label': 'Billing',
  'address.formModal.defaultShipping.label': 'Default Shipping Address',
  'address.formModal.defaultBilling.label': 'Default Billing Address',
  'address.formModal.addressLabel.label': 'Address Label',
  'address.formModal.lastName.label': 'Last Name',
  'address.formModal.country.label': 'Country',
  'address.formModal.country.palceholder': 'Please Select',
  'address.tipString.add': 'Address add',
  'address.tipString.edit': 'Address editing',
  'address.tipString.delete': 'Address delete',
  'address.tipString.action': 'Address action',
  'address.tipString.setDefault.shipping': 'Set default shipping address',
  'address.tipString.setDefault.billing': 'Set default billing address',
  'address.modalType.title.add': 'Add address',
  'address.modalType.title.edit': 'Update Address',
  'address.checkAction.Permission.tips': '{checkType} has been disabled by the system administrators.',
  'address.defaultAddress.details': 'No default {addressTypeName} address',
  'address.defaultAddress.title': 'Default {addressTypeName} Address',
  // buy again
  'buyAgain.datePicker.from.label': 'From: ',
  'buyAgain.datePicker.to.label': 'To: ',
  'buyAgain.searchInput.placeholder': 'Search By Product Name,SKU',
  'buyAgain.thead.product.label': 'Product',
  'buyAgain.thead.sku.label': 'SKU',
  'buyAgain.thead.lastOrdered.label': 'Last Ordered',
  'buyAgain.thead.price.label': 'Price',
  'buyAgain.thead.qty.label': 'Qty',
  'buyAgain.thead.action.label': 'Action',
  'buyAgain.addToCart.btn': 'Add to Cart',
  'buyAgain.addAllToCart.btn': 'Add all to Cart',
  'buyAgain.addToShoppingList.btn': 'Add to Shopping List',
  'buyAgain.createModal.title.label': 'Create New Shopping List',
  'buyAgain.createModal.name.label': 'Shopping List Name',
  'buyAgain.createModal.name.required': 'Shopping List Name is required',
  'buyAgain.createModal.description.label': 'Description',
  'buyAgain.chooseModal.title.label': 'Choose Shopping List',
  'buyAgain.chooseModal.description.label': 'Description',
  'buyAgain.modal.cancel': 'Cancel',
  'buyAgain.modal.save': 'Save',
  'buyAgain.modal.add': 'Add',
  'buyAgain.modal.close': 'Close',
  // dashboard
  'dashboard.company.selected': 'selected',
  'dashboard.user.name.label': 'Super Admin: ',
  'dashboard.user.email.label': 'Email: ',
  'dashboard.company.counts': 'Number of Company Accounts',
  'dashboard.action.title': 'Company Admin Impersonation',
  'dashboard.search.placeholder': 'Search Company Name/Email',
  'dashboard.filter.companyName': 'Company Name',
  'dashboard.filter.adminName': 'Company Admin',
  'dashboard.top.salesInfo.label': 'Viewing as ',

  // invoices
  'invoices.title': 'INVOICES',
  // invoicesPayment
  'invoices.payment.dueTip': 'You have invoices due today',
  'invoices.payment.overDueTip': 'You have overdue invoices',
  'invoices.payment.title': 'Make an Online Payment',
  'invoices.payment.totalBalance': 'Open Balance:',
  'invoices.payment.desc': 'To make a payment specify the invoice(s) and amount(s) you would like to pay:',
  'invoices.pendingPayment.tip': 'You have pending payments',
  'invoices.payment.description': `You have made at least one online payment in the list below that
  may still be processing. If you have any questions, please contact
  customer service.`,
  'invoices.payment.checkout': 'Proceed to Checkout',
  'invoices.payment.MakePayment': 'Make Payment',
  'invoices.payment.tip': 'Please select a line',
  'invoices.payment.select.title': 'Secure Payment',
  'invoices.payment.select.back': 'Back',
  'invoices.payment.select.payment': 'Select a payment method:',
  'invoices.payment.select.date': 'Date:',
  'invoices.payment.select.account': 'Company Name:',
  'invoices.payment.select.user': 'User:',
  'invoices.payment.select.memo': 'Memo:',
  'invoices.paymentTable.thead.paymentDate': 'Payment Date',
  'invoices.paymentTable.thead.invoiceNumber': 'Invoice #',
  'invoices.paymentTable.thead.paymentMethod': 'Payment Method',
  'invoices.paymentTable.thead.amount': 'Amount',

  'invoices.table.thead.invoiceNumber': 'Invoice#',
  'invoices.table.thead.orderNumber': 'Order#',
  'invoices.table.thead.createdAt': 'Invoice Date',
  'invoices.table.thead.dueDate': 'Due Date',
  'invoices.table.thead.originalBalance': 'Invoice Total',
  'invoices.table.thead.discountTotal': 'Discount Total',
  'invoices.table.thead.openBalance': 'Amount Due',
  'invoices.table.thead.status': 'Status',
  'invoices.table.thead.actions': 'Actions',
  'invoices.table.thead.total': 'Total',
  'invoices.table.thead.totalPrice': 'Open Balance',
  'invoices.table.thead.amount': 'Amount to Pay',
  'invoices.table.action.view': 'View',
  'invoices.table.action.pay': 'Pay',
  'invoices.search.placeholder': 'Search my invoices...',
  'invoices.search.selectOptions.all': 'All',
  'invoices.search.selectOptions.invoiceNum': 'Invoice#',
  'invoices.search.selectOptions.salesOrderNum': 'Order#',
  'invoices.list.exportCSV': 'Export as CSV file',
  'invoices.filters': 'Filters',
  'invoices.filters.dateCreatedBeginAt': 'Date Created',
  'invoices.filters.dateCreatedBeginAt.text': 'To',
  'invoices.filters.dateCreatedEndAt': '',
  'invoices.filters.placeholder': 'Please Select',
  'invoices.list.status': 'Status',
  'invoices.filters.clear': 'CLEAR',
  'invoices.filters.apply': 'APPLY',
  'invoices.status.open': 'Open',
  'invoices.status.partialPaid': 'Partially Paid',
  'invoices.status.paid': 'Paid',
  'invoices.paymentType.unknown': 'Unknown',

  // invoiceDetail
  'invoiceDetail.header.print': 'Print',
  'invoiceDetail.title': 'Invoice',
  'invoiceDetail.header.tips.invoicePaidCompleted.title': 'Invoice Paid In Full',
  'invoiceDetail.header.tips.invoicePaidInCompleted.title': 'Payment Due in {pastDueDays} Days',
  'invoiceDetail.header.tips.invoicePaidInCompleted.pastDue.title': 'Payment is {pastDueDays} days past due',
  'invoiceDetail.header.tips.invoicePaidCompleted': 'Thank you!',
  'invoiceDetail.header.tips.invoicePaidInCompleted': 'Your invoice due in {pastDueDays} days, please make a payment.',
  'invoiceDetail.header.tips.invoicePaidInCompleted.pastDue': 'Your invoice is {pastDueDays} days past due, please make a payment.',
  'invoiceDetail.generalInfo.title': 'General Information',
  'invoiceDetail.generalInfo.orderNumber': 'Order Number:',
  'invoiceDetail.generalInfo.po': 'PO#:',
  'invoiceDetail.address.billing': 'Billing Address',
  'invoiceDetail.address.shipping': 'Shipping Address',
  'invoiceDetail.amount.title': 'Invoice Dates and Totals',
  'invoiceDetail.amount.createdAt': 'Invoice Date:',
  'invoiceDetail.amount.dueDate': 'Due Date:',
  'invoiceDetail.amount.subtotal': 'Subtotal:',
  'invoiceDetail.amount.freight': 'Freight:',
  'invoiceDetail.amount.salesTax': 'Sales Tax:',
  'invoiceDetail.amount.invoiceGrandTotal': 'Grand Invoice Total:',
  'invoiceDetail.amount.amountPaid': 'Amount Paid:',
  'invoiceDetail.amount.amountDue': 'Amount Due:',
  'invoiceDetail.amount.pay': 'Pay Now',
  'invoiceDetail.shipments.title': 'Shipments',
  'invoiceDetail.shipments.shipDate': 'Ship Date',
  'invoiceDetail.shipments.shipVia': 'Ship Via',
  'invoiceDetail.shipments.trackingNumber': 'Tracking Number',
  'invoiceDetail.shipments.comments': 'Comments',
  'invoiceDetail.payments.title': 'Payments',
  'invoiceDetail.payments.dateReceived': 'Date Received',
  'invoiceDetail.payments.amount': 'Amount',
  'invoiceDetail.payments.transactionType': 'Transaction Type',
  'invoiceDetail.payments.paymentType': 'Payment Type',
  'invoiceDetail.payments.reference': 'Reference',
  'invoiceDetail.payments.noData': 'No Records Available',
  'invoiceDetail.lineItem.item': 'Item',
  'invoiceDetail.lineItem.title': 'Invoice Line Items',
  'invoiceDetail.lineItem.description': 'Description',
  'invoiceDetail.lineItem.qty': 'Qty',
  'invoiceDetail.lineItem.unitPrice': 'Unit Price',
  'invoiceDetail.lineItem.discountTotal': 'Discount Total',
  'invoiceDetail.lineItem.lineTotal': 'Line Total',
  'invoiceDetail.lineItem.comments': 'Comments',
  'invoiceDetail.termsConditions.title': 'Terms and Conditions',

  // invoice-receipt
  'invoiceReceipt.title': 'Thanks for your payment!',
  'invoiceReceipt.payment.overview': 'Payment Overview',
  'invoiceReceipt.payment.id': 'Payment #',
  'invoiceReceipt.payment.receiveDate': 'Payment Received On',
  'invoiceReceipt.payment.transactionType': 'Transaction Type',
  'invoiceReceipt.payment.type': 'Payment Type',
  'invoiceReceipt.payment.total': 'Payment Total',
  'invoiceReceipt.payment.reference': 'Reference',
  'invoiceReceipt.payment.memo': 'Memo',
  'invoiceReceipt.lines.title': 'Invoices Paid',
  'invoiceReceipt.lines.des': 'You made payments towards the invoices shown below',
  'invoiceReceipt.lines.invoiceId': 'Invoice #',
  'invoiceReceipt.lines.paidAmount': 'Amount Paid',

  // tips
  'tips.notPermission': 'You can\'t access to this page.',
  'tips.networkError': 'Something went wrong,Please contact us.',
  'tips.globalError': 'Woops! Something went wrong! Please try again later.',
  'tips.createCompanyApproved': 'Your company has been approved.',
  'tips.createCompanyChecking': 'Please wait at least 24 hours for approval. Check on this page for the approval status.',
  'tips.cannotEnterPage': 'You can\'t access to this page.',
  'tips.addProductsSuccess': 'Product(s) added to shopping cart successfully.',
  'tips.productOutOfStock': 'Failed to add item due to insufficient stock',
  'tips.reorderFailedNoLongerForSale': 'One or more items are no longer for sale',
  'tips.reorderFailedOutOfStock': 'One or more items are out of stock',
  'tips.buyAgainFailedNoLongerForSale': 'This product is no longer for sale',
  'tips.buyAgainFailedOutOfStock': 'This product is out of stock',
  'tips.deleteUser': 'You have successfully deleted the user',
  'tips.confirmDeleteUser': 'Are you sure you want to delete this user?',
  'tips.chooseShoppingList': 'Choose Shopping List',
  'tips.addToShoppingListSuccess': 'Product(s) added to the shopping list successfully.',
  'tips.confirmDeleteShoppingList': 'Are you sure you want to delete this shopping list?',
  'tips.deleteShoppingListFailed': 'Shopping List was not deleted. Please try again.',
  'tips.shoppingListExist': 'The shopping list you are to looking is not exist.',
  'tips.shoppingListUpdateSuccess': 'Shopping List Info changed successfully',
  'tips.shoppingListNumberLimit': 'The quantity of this product is zero and cannot be selected',
  'tips.confirmDeleteShoppingListItem': 'Are you sure you want to delete selected item(s) from the shopping list?',
  'tips.deleteShoppingListItemSuccess': 'Product(s) deleted from the shopping list successfully',
  'tips.updateQtySuccess': 'Product quantity updated successfully',
  'tips.updateOptionsSuccess': 'Product options updated successfully',
  'tips.clearedShoppingList': 'Shopping List was cleared successfully',
  'tips.clearShoppingListFailed': 'Shopping List was not cleared. Please try again.',
  'tips.noItemList': 'You have no items in your list.',
  'tips.updateShoppingListSuccess': 'Shopping lists status updated successfully',
  'tips.searchProducts': 'Please search for products by SKU/product name or add from file',
  'tips.selectProducts': 'Please select products you want to add to list',
  'tips.invalidQty': 'Please input valid quantity',
  'tips.confirmDeleteAddress': 'Are you sure you want to delete the address?',
  'tips.deleteAddressSuccess': 'You have successfully deleted the address',
  'tips.confirmSetShippingAddress': 'Are you sure you want to set the address as default shipping address?',
  'tips.setShippingAddressSuccess': 'You have successfully set the address to default',
  'tips.confirmSetBillingAddress': 'Are you sure you want to set the address as default billing address?',
  'tips.setBillingAddressSuccess': 'You have successfully set the address to default',
  'tips.duplicateRfqCompany': 'Please sign in before submitting the quote in order to receive special pricing assigned to your corporate account.',
  'tips.addToQuoteSuccess': 'Product(s) added to quote successfully',
  'tips.addToQuoteDiffCurrency': 'Your quote request can only contain products in the same currency, please empty your quote basket before adding product in another currency.',
  'tips.checkoutErr.notSalesRepErr': 'Please select a Company.',
  'tips.checkoutErr.networkError': 'Could not complete the order, please try it again.',
  'tips.checkoutErr.qtyErr': 'Some products do not pass the quantity verification.',
  'tips.noPermissionToCheckout': 'Juniors cannot checkout, please contact the merchant to authorize.',
  'tips.productsNotAvailable': 'Some products cannot be purchased, please contact Admin.',
  // validation
  'validation.companyNameVoid': 'You must enter a company name',
  'validation.emailIncorrect': 'You must enter a valid email',
  'validation.phoneIncorrect': 'You must enter a valid phone',
  'validation.uploadNotCsv': 'Please upload a CSV file.',
  'validation.emptyFiles': 'Empty file, please upload another.',
  'validation.emptyQty': 'Please set the quantity of the product.',
  'validation.fistNameIncorrect': "The 'First Name' field can't be left empty.",
  'validation.lastNameIncorrect': "The 'Last Name' field can't be left empty.",
  'validation.emptyShoppingListName': 'Please fill in a Shopping List Name',
  'validation.emptyShoppingListSelect': 'Please select an item',
  'validation.emptyShoppingListSubmit': 'You have no items in your list',
  'validation.shoppingListQtyValidError': 'Please review your shopping list, one or more items have an invalid quantity.',
  'validation.emptyShoppingList': 'You have no products in list.',
  'validation.emptySelectedShoppingList': 'Please select at least one item to add to cart.',
  'validation.incompleteOptions': 'Please fill out product options first.',
  'validation.unValidProduct': 'Some Products No variant Id or Product Id',
  'validation.addressForm[firstName]': "The 'FirstName' field can't be left empty.",
  'validation.addressForm[lastName]': "The 'LastName' field can't be left empty.",
  'validation.addressForm[addressLine1]': "The 'AddressLine 1' field can't be left empty.",
  'validation.addressForm[city]': "The 'City' field can't be left empty.",
  'validation.addressForm[country]': "The 'Country' field can't be left empty.",
  'validation.addressForm[zipCode]': "The 'ZipCode' field can't be left empty.",
  'validation.addressForm[state]': "The 'State' field can't be left empty.",
  'validation.addressForm[phoneNumber]': 'Please enter a valid phone number.',
  'validation.cannotAddToShoppingList': 'Please select all the required product options to add to shopping list',
  'validation.cannotAddToQuote': 'Please select all the required product options to add to quote',
  'validation.emailExist': 'Please sign in to your account before submitting the quote in order to get special price assigned to your corporate account',
  'validation.modifier.invalidNumber': 'Please enter a valid number in {display_name}.',
  'validation.modifier.invalidIntegerNumber': 'Please enter an integer number in {display_name}.',
  'validation.modifier.limitedNumberLowestValue': 'The lowest value of {display_name} is {lowest}.',
  'validation.modifier.limitedNumberHighestValue': 'The highest value of {display_name} is {highest}.',
  'validation.modifier.limitedTextMaxLength': 'The max length of {display_name} is {max_length}.',
  'validation.extraFields.fieldNameIsRequired': '{fieldName} is required',
  'validation.extraFields.limitedTextMaxLength': 'Max length of {fieldName} is {maximumLength}',
  'validation.extraFields.limitedNumberHighestValue': 'Max number of {fieldName} is {maximumValue}',
  'validation.productWithoutSKU': 'Can not add products without SKU.',
  'validation.noProductsAdded': 'No products being added.',
  'validation.userEmailMultipleUsed': 'This email is used by multiple customers in your store. Please use another email.',
  'validation.userEmailOtherUsed': 'This email is used by a customer within another company.',
  'validation.userEmailOwnUsed': 'user detected in your company, we will allow current storefront access for email: {userEmail}',
  'validation.userEmailSuperAdminUsed': 'This email is used by a super admin',

  // The following is not added to the document
  'invoices.source.bc': 'BC',
  'invoices.source.erp': 'ERP',
  'invoices.table.thead.status.open': 'Open',
  'invoices.table.thead.status.ordered': 'Ordered',
  'invoices.table.thead.status.expired': 'Expired',
  'invoiceDetail.header.downloadPDF': 'Download PDF',
  'invoices.table.thead.items': 'Items',
  'invoices.table.thead.aging': 'Aging',
  'invoices.table.thead.type': 'Type',
  'invoices.table.thead.source': 'Source',
  'invoices.action.view.order': 'View Order',
  'invoices.back.to.table': 'Back to All Invoices',
  'nav.button.invoicesPayment': 'Invoices payment',
  'nav.button.invoicePaymentReceipt': 'Invoices payment receipt',
  'tpa.form.account.submit.button': 'Create Account',
  'quotes.create.button': 'Create New Quote',
  'rfq.company.bundleb2b': 'Bundle B2B',
  'rfq.person.abc.corp': 'ABC Corp.',
  'rfq.detail.email': 'Email: ',
  'rfq.detail.phone': 'Phone: ',
  'createQuote.options.currently.label': 'Currently:',
  'createQuote.options.remove.label': 'Remove this file',
  'createQuote.options.maximum.fileSize': 'Maximum file size is',
  'createQuote.options.fileType': 'file types are',
  'createQuote.options.chooseOptions': 'Choose Options',

  // limitation
  'limitation.tip.context.left': ' and you can',
  'limitation.tip.context.middleText': 'upgrade plan',
  'limitation.tip.context.rightText': 'to increase the limit.',
  'limitation.upgrade.dialog.context': 'Contact Us',
  'limitation.upgrade.dialog.email': 'Email:',
  'limitation.upgrade.dialog.phone': 'Phone:',
  'limitation.upgrade.dialog.title': 'Upgrade Plan',
  'limitation.error.dialog.title': 'Limit reached',

  'limitation.warning.left.usermanagement': 'Notes: the number of user management in the store has exceeded 80% of the maximum limit',
  'limitation.error.left.usermanagement': 'Notes: the number of user managements in the store has exceeded the maximum limit',
  'limitation.warning.left.addressbook': 'Notes: the number of addressbook in the store has exceeded 80% of the maximum limit',
  'limitation.error.left.addressbook': 'Notes: the number of addressbook in the store has exceeded the maximum limit',
  'limitation.warning.left.orders': 'Notes: the number of orders in the store has exceeded 80% of the maximum limit',
  'limitation.error.left.orders': 'Notes: the number of orders in the store has exceeded the maximum limit',
  'limitation.warning.left.quotes': 'Notes: the number of quotes in the store has exceeded 80% of the maximum limit',
  'limitation.error.left.quotes': 'Notes: the number of quotes in the store has exceeded the maximum limit',
  'limitation.warning.left.shoppingLists': 'Notes: the number of shoppingLists in the store has exceeded 80% of the maximum limit',
  'limitation.error.left.shoppingLists': 'Notes: the number of shoppingLists in the store has exceeded the maximum limit',
  'limitation.warning.left.company': 'Notes: the number of companies in the store has exceeded 80% of the maximum limit',
  'limitation.error.left.company': 'Notes: the number of companies in the store has exceeded the maximum limit',
}
