import b3request from './common/api/base'
import { Alert } from './common/utils'

(function () {
  function insertScript(url) {
    const el = document.createElement('script')
    el.setAttribute('src', url)
    document.querySelector('body').append(el)
  }

  async function getScriptUrl() {
    try {
      const {
        store_hash: storeHash,
      } = window.jsContext
      const { storefrontUrl } = await b3request.get('/api/v2/stores/auto-loaders', { params: { storeHash } })
      insertScript(storefrontUrl)
    } catch (error) {
      Alert.error(error.message)
    }
  }

  getScriptUrl()
}())
