import { merge } from 'lodash'

export default merge({}, {
  'pdp.shoppinglist.container': '.productView-details .productView-options',
  'dashboard.container': '.container .page',
  'dashboard.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
  'dashboard.button.container--s': '#navPages-account .navPage-subMenu-list',
  'dashboard.endMasquerade.container': '.navUser',
  'tpa.container': '.page .page-content',
  'tpa.guest.container': '.account-body',
  'tpa.button.container': '.navUser-section--alt',
  'tpa.button.container--s': '#menu .navPages-list--user',
  'shoppinglists.container': '.container .page',
  'shoppinglists.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
  'shoppinglists.button.container--s': '#navPages-account .navPage-subMenu-list',
  'shoppinglist.container': '.container .page',
  'addressBook.container': '.container .page',
  'addressBook.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
  'addressBook.button.container--s': '#navPages-account .navPage-subMenu-list',
  'orders.container': '.account',
  'orderDetail.container': '.container .page',
  'quickOrderPad.container': '.container .page',
  'quickOrderPad.button.container': '.navUser-section--alt',
  'quickOrderPad.button.container--s': '#menu .navPages-list--user',
  'buyAgain.container': '.container .page',
  'buyAgain.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
  'buyAgain.button.container--s': '#navPages-account .navPage-subMenu-list',
  'userManagement.container': '.container .page',
  'userManagement.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
  'userManagement.button.container--s': '#navPages-account .navPage-subMenu-list',
  'rfq.quote.container': '.container .page',
  'quotesList.container': '.container .page',
  'quotesList.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
  'quotesList.button.container--s': '#navPages-account .navPage-subMenu-list',
  'invoices.container': '.container .page',
  'invoicesPayment.container': '.container .page',
  'invoices.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
  'invoices.button.container--s': '#navPages-account .navPage-subMenu-list',
  'invoiceDetail.container': '.container .page',
  'invoiceDetail.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
  'invoiceDetail.button.container--s': '#navPages-account .navPage-subMenu-list',
  'invoiceReceipt.container': '.container .page',
  'invoiceReceipt.button.container': '.navBar.navBar--sub.navBar--account .navBar-section',
  'invoiceReceipt.button.container--s': '#navPages-account .navPage-subMenu-list',
  'myQuote.button.container': '.navUser-section--alt',
  'myQuote.button.container--s': '#menu .navPages-list--user',
  'cartActions.container': '.cart-actions',
  'addToCart.container': '#add-to-cart-wrapper',
  'addToCart.quickView.container': '.productView--quickView #add-to-cart-wrapper',
  'addToCart.button.container': '#form-action-addToCart',
  'logout.button.container': '[href="/login.php?action=logout"]',
}, window.b3themeConfig?.useContainers)
