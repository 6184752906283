const CryptoJS = require('crypto-js')
const tripledes = require('crypto-js/tripledes')

const encryptedStr = 'B3Info'

const cipherText = text => {
  if (!text) return ''
  text = typeof text === 'boolean' ? text.toString() : text
  return tripledes.encrypt(text, encryptedStr).toString()
}

const plainText = text => {
  if (!text) return ''
  return tripledes.decrypt(text, encryptedStr).toString(CryptoJS.enc.Utf8)
}

const setCipherTextToStorage = (key, value) => {
  sessionStorage.setItem(key, cipherText(value))
}

const getPlainTextFromStorage = key => plainText(sessionStorage.getItem(key))

export default {
  cipherText,
  plainText,
  setCipherTextToStorage,
  getPlainTextFromStorage,
}
