import text from '../text'

export const B3CompanyStatus = {
  PENDING: '0',
  APPROVED: '1',
  REJECT: '2',
}

export const B3Role = {
  ADMIN: '0',
  SENIOR: '1',
  JUNIOR: '2',
  SALESREP: '3',
}

export const QuoteStatus = {
  New: '0',
  Sent: '1',
  Ordered: '2',
  Expired: '3',
  Opened: '4',
  Draft: '5',
  AwaitingApproval: '6',
  Rejected: '7',
}

export const B3ShoppListStatus = {
  0: text['shopping.list.status.approved'],
  20: text['shopping.list.status.deleted'],
  30: text['shopping.list.status.draft'],
  40: text['shopping.list.status.readyForApproval'],
}

export const shoppingListStatusDefaultOptions = [
  {
    id: '1',
    title: text['shopping.list.status.showAllStatus'],
    status: 'all',
    isShow: false,
  },
  {
    id: '3',
    title: text['shopping.list.status.showReadyForApproval'],
    status: '40',
    isShow: true,
  },
  {
    id: '2',
    title: text['shopping.list.status.showDraft'],
    status: '30',
    isShow: true,
    extraIsShowField: 'isShowDraftBtn',
  },
  {
    id: '0',
    title: text['shopping.list.status.showApproved'],
    status: '0',
    isShow: true,
  },
]

export const getB3ConstantLabel = (B3Constant, status) => {
  let label = ''
  Object.entries(B3Constant).forEach(([key, value]) => {
    if (value === status) label = key
  })
  return label
}

export const shortMonths = {
  1: text['global.shortMonths.Jan'],
  2: text['global.shortMonths.Feb'],
  3: text['global.shortMonths.Mar'],
  4: text['global.shortMonths.Apr'],
  5: text['global.shortMonths.May'],
  6: text['global.shortMonths.Jun'],
  7: text['global.shortMonths.Jul'],
  8: text['global.shortMonths.Aug'],
  9: text['global.shortMonths.Sep'],
  10: text['global.shortMonths.Oct'],
  11: text['global.shortMonths.Nov'],
  12: text['global.shortMonths.Dec'],
}

export const b3ExtraFieldTypes = {
  0: 'text',
  1: 'number',
  2: 'multiline',
}

export const paymentStatus = {
  0: 'Incomplete',
  1: 'Awaiting Processing',
  2: 'Processing',
  3: 'Completed',
  4: 'Refunded',
}

export const APP_NAME_MAP = {
  BundleB2B: 'BundleB2B',
  IP: 'Invoice Portal',
}
