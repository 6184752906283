'use strict';

const utils = require('handlebars-utils');
const SafeString = require('handlebars').SafeString;
const common = require('../lib/common.js');

export const DEFAULT_IMAGE_URL = 'https://cdn11.bigcommerce.com/s-1i6zpxpe3g/stencil/cd9e3830-4c73-0139-8a51-0242ac11000a/e/4fe76590-73f1-0139-3767-32e4ea84ca1d/img/ProductDefault.gif'

export default function(image, presetName, defaultImageUrl) {
  var size = 'original';
  var width;
  var height;

  // handle pick list product image
  if (utils.isString(image) && image.indexOf('{:size}') !== -1) {
    return new SafeString(image.replace('{:size}', size)).toString() || DEFAULT_IMAGE_URL
  }

  if (!utils.isObject(image) || !utils.isString(image.data)
      || !common.isValidURL(image.data) || image.data.indexOf('{:size}') === -1) {
      // return empty string if not a valid image object
      defaultImageUrl = defaultImageUrl ? defaultImageUrl : '';
      return utils.isString(image) ? image : defaultImageUrl;
  }

  if (Number.isInteger(image.width) && Number.isInteger(image.height)
      && Number.isInteger(width) && Number.isInteger(height)) {
      size = `${Math.min(image.width, width)}x${Math.min(image.height, height)}`
  }

  return new SafeString(image.data.replace('{:size}', size)) || defaultImageUrl;
}
