import text from '../../common/text'

export default (key, options) => {
  let value = text[key]
  if (!options) {
    return value
  }
  const { hash: params } = options
  const paramKeys = Object.keys(params) || []
  paramKeys.forEach(key => {
    value = value.replace(`{${key}}`, params[key])
  })
  return value
}
